<div
  #map
  class="map-wrapper"
  leaflet
  *ngIf="center"
  [leafletOptions]="options"
  [(leafletCenter)]="center"
  [leafletZoom]="14"
  [leafletLayers]="layers"
></div>
<button mat-flat-button color="primary" (click)="onGenerateImage()">
  Generate image
</button>

<a #downloadLink></a>
